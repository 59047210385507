import { PageElement, html } from 'Elements';
import styles from './styles.css.js';

class TabGroupPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles
    ];
  }
    
  onInput(e) {
    alert(e.target.value);
  }

  onClick() {
    alert('k');
  }

  render() {

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;sl-tab-group&gt;</h3>
        <div>Boîte à onglets, composant amélioré</div>
      </div>
      <sl-tab-group>
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">

          <tab-group level="0">
            <sl-tab slot="nav" panel="general">General</sl-tab>
            <sl-tab slot="nav" panel="custom">Custom</sl-tab>
            <sl-tab slot="nav" panel="form">Form</sl-tab>
            <sl-tab slot="nav" panel="disabled" disabled>Disabled</sl-tab>

            <sl-tab-panel name="general">
              <tab-group placement="start" level="1">
                <sl-tab slot="nav" panel="general">General</sl-tab>
                <sl-tab slot="nav" panel="custom">Custom</sl-tab>
                <sl-tab slot="nav" panel="advanced">Advanced</sl-tab>
                <sl-tab slot="nav" panel="disabled" disabled>Disabled</sl-tab>

                <sl-tab-panel name="general">This is the general tab panel.</sl-tab-panel>
                <sl-tab-panel name="custom">This is the custom tab panel.</sl-tab-panel>
                <sl-tab-panel name="advanced">This is the advanced tab panel.</sl-tab-panel>
                <sl-tab-panel name="disabled">This is a disabled tab panel.</sl-tab-panel
              </tab-group>
            </sl-tab-panel>

            <sl-tab-panel name="custom">
              custom
            </sl-tab-panel>

            <sl-tab-panel name="form">
              <sl-input label="Name" placeholder="Enter your name" @sl-input=${this.onInput}></sl-input>
              <sl-button @click="${this.onClick}">Click me</sl-button>
            </sl-tab-panel>
            <sl-tab-panel name="disabled">This is a disabled tab panel.</sl-tab-panel>
          </tab-group>
        </sl-tab-panel>
      </sl-tab-group>
    `;
  }
}

customElements.define('page-tests-tab-group', TabGroupPage);
import { PageElement, html, css } from 'Elements';
import { Router } from 'Router';

import './modal-dialog.js';
import './modal-drawer.js';
import './reveal.js';
import './apex-chart/index.js';
import './section-header.js';
import './sl-tab-group.js';
import './tab-group.js';
import './i18n.js';
import './pretty-json.js';
import './context-menu.js';
import './cherry-editor.js';
import './select-permissions.js';
import './select-enabler.js'
import './select-date-periods.js'
import './select-search.js';
import './select-search-tree.js';
import './select-severity.js';
import './select-jobs.js';
import './data-periods-group.js';
import './data-paginator.js';
import './input-business-hours.js';
import './range-numbers.js';
import './contract-sla.js';
import './toast-editor.js';
import './image-bank.js';

class TestUXPage extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        table {
          width:100%;
        }

        th {
          text-align:left;
        }

        tr {
          cursor:pointer;
        }

        th, td {
          padding:5px;
        }

        tbody tr:nth-child(odd) {
          background-color: var(--table-list-odd-background-color);
        }
      `
    ]
  }
  
  go(ev) {
    const tr = ev.target.closest('tr');
    Router.go('/private/dev/components/'+tr.getAttribute('href'));
  }

  render() {

    const components = [
      { name: 'i18n',                   note: 'Internationalisation' },
      { name: 'modal-dialog',           note: 'Boîte de dialogue modale' },
      { name: 'modal-drawer',           note: 'Fenêtre surgissante modale' },
      { name: 'reveal',                 note: 'Wrapper reveal.js' },
      { name: 'apex-charts',            note: 'Wrapper apexcharts' },
      { name: 'section-header',         note: 'Section de pages' },
      { name: 'sl-tab-group',           note: 'Onglets natif Shoelace' },
      { name: 'tab-group',              note: 'Onglets améliorés avec paramètres dans l\'URL' },
      { name: 'pretty-json',            note: 'Afficher du JSON de manière propre et pouvoir expand/collapse des niveaux' },
      { name: 'context-menu',           note: 'Menu contextuel dynamique' },
      { name: 'cherry-editor',          note: 'Editeur Cherry Markdown' },
      { name: 'select-permissions',     note: 'Menu à choix multiple pour les permissions' },
      { name: 'select-enabler',         note: 'Menu à choix multiple (boolean, recursive)' },
      { name: 'select-date-periods',    note: 'Menu de sélection de périodes' },
      { name: 'select-search',          note: 'Sélecteur avec recherche' },
      { name: 'select-search-tree',     note: 'Sélecteur arborescence avec recherche' },
      { name: 'select-severity',        note: 'Sélecteur de sévérité' },
      { name: 'select-jobs',            note: 'Sélecteur de métier' },
      { name: 'data-periods-group',     note: 'Widget de groupement par période' },
      { name: 'data-paginator',         note: 'Widget de pagination' },
      { name: 'input-business-hours',   note: 'Sélecteur de plage horaires - inputs' },
      { name: 'range-numbers',          note: 'Sélecteur de plage horaires - range' },
      { name: 'contract-sla',           note: 'Paramétrage SLA' },
      { name: 'toast-editor',           note: 'ToastUI Editor' },
      { name: 'image-bank',             note: 'Image Bank' },      
    ];

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header><br/>
      <table>
        <tbody>
          ${components.map(c => html`<tr @click=${this.go} href="${c.name}"><td>${c.name}</td><td>${c.note}</td></tr>`)}
        </tbody>
      </table>
      <br/>
    `;
  }
}

customElements.define('page-tests-ux', TestUXPage);
import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';

class SelectPermissionsPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        .flex {
          display:flex;
          align-items:center;
          gap:20px;
        }

        .flex > * {
          width:100%;
        }

        b {
          font-size:2em;
          height:40px;
        }
      `
    ]
  }

  static get properties() {
    return {
      value: { type: String },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.value = '';
  }

  async updated(changedProperties) {
    if (changedProperties.has('value')) {
      //this._log.debug('updated: value:', this.value);
    }
  }

  onChange(ev) {
    this.value = ev.detail.value;
    this.requestUpdate();
  }

  render() {

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;select-permissions&gt;</h3>
        <div>Un selecteur de permission</div>
      </div>

      <sl-tab-group>  
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">
          <div class="flex">
            <select-permissions value="${this.value}" @sl-change=${this.onChange}></select-permissions>
            <div>Valeur: ${this.value}</div>
          </div>
        </sl-tab-panel>
      </sl-tab-group>
      
    `;
  }
}

customElements.define('page-tests-select-permissions', SelectPermissionsPage);
import { PageElement, html, css } from 'Elements';
import App from 'App';

const tests = {
  text1: {
    fr: 'texte provenance classe parent',
    en: 'text from parent class',
  },
  text2: {
    fr: 'texte provenance classe actuelle',
    en: 'text from current class',
  }
}

class PageI18n extends PageElement {
  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            text1: tests.text1.en
          },
        },
        french:{
          translation: {
            text1: tests.text1.fr
          }
        }
      }
    ];
  }
}

class PageI18nMain extends PageI18n {

  static get styles() {
    return [
      super.styles,
      css`
        pre {
          padding:20px;
          margin:10px;
          font-size:0.9em;
          border:1px solid var(--sl-color-neutral-300)
        }

        p {
          font-size:1.2em;
          margin-left:40px;
        }

        .flex {
          display:flex;
        }

        sl-tree {
          padding:10px;
          margin:10px;
          background-color:var(--sl-panel-background-color);
        }

        sl-tree-item::part(label) {
          font-size:0.9rem;
        }

        sl-tree-item::part(expand-button) {
          padding:3px;
        }
      `
    ]
  }

  static get properties() {
    return {
      testPassed: { type: Number },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            text2: tests.text2.en
          },
        },
        french:{
          translation: {
            text2: tests.text2.fr
          }
        }
      }
    ];
  }

  constructor() {
    super();
    this.testPassed = 0;
  }

  firstUpdated() {
    super.firstUpdated();
    const currentLg = App.config.lg;
    const boldTags = this.shadowRoot.querySelectorAll('strong');
    boldTags.forEach(tag => {
      const id = tag.getAttribute('tid');
      if (tag.textContent === tests[id][currentLg]) {
        this.testPassed+=1;
      }
    });
  }

  render() {
    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <h3>Internationalisation des composants (i18next)</h3>
      Des clés de traductions <a href="https://www.i18next.com/" target="i18n">i18next</a> peuvent être définies dans tous les composants qui surchargent les composants <b>BaseElement</b>, <b>FullPageElement</b> et <b>PageElement</b>.
      L'héritage de ces clés est géré automatiquement et recursivement : le composant final contiendra les clés de traductions de tous les composants parents ainsi que les siennes s'il en a déclaré. 
      Ce qui peut ainsi éviter d'avoir à déclarer des doublons.
      <br/><br/>
      
      <sl-tab-group>
        
        <sl-tab slot="nav" panel="tab1">MaPage</sl-tab>
        <sl-tab slot="nav" panel="tab2">MonAutrePage</sl-tab>
        <sl-tab slot="nav" panel="tab3">Explications</sl-tab>
        <sl-tab slot="nav" panel="test">Test</sl-tab>

        <sl-tab-panel name="tab1">
          
          Le composant <b>MaPage</b> est une surchage du composant <b>PageElement</b>. On y définit une clé de traduction <b>text1</b>.

          <pre>import { PageElement } from 'Components/pages/PageElement.js';

class MaPage extends PageElement {

  static get translations() {
    return [
      super.translations, // hérite des clés de traductions du composant PageElement
      {
        english:{
          translation: {
            text1: 'this is text 1'
          },
        },
        french:{
          translation: {
            text1: 'ceci est le texte 1'
          }
        }
      }
    ];
  }

  [...]
}
          </pre>
        </sl-tab-panel>

        <sl-tab-panel name="tab2">

          Le composant <b>MonAutrePage1</b> est une surcharge du composant <b>MaPage</b>. On y définie une clé de traduction <b>text2</b>.
          La clé <b>text1</b> est héritée de <b>MaPage</b> et donc disponible.
          
          <pre>import { html } from 'Composants/pages/index.js'; // importe une méthode native lit-element
import './MaPage.js  // importe la classe MaPage';

class MonAutrePage1 extends MaPage {

  static get translations() {
    return [
      super.translations, // hérite des clés de traductions du composant MaPage
      {
        english:{
          translation: {
            text2: 'this is text 2'
          },
        },
        french:{
          translation: {
            text2: 'ceci est le texte 2'
          }
        }
      }
    ];
  }

  [...]

  render() {
    return html\`
      $\{this._tl('text1')\} // affiche la traduction de la clé text1 en fonction de la langue courante
      $\{this._tl('text2')\} // affiche la traduction de la clé text2 en fonction de la langue courante
    \`;
  }
}
          </pre>
        </sl-tab-panel>

        <sl-tab-panel name="tab3">
          <b>MonAutrePage1</b> hérite des clés de traductions de <b>MaPage</b> qui elle-même hérite des clés de traductions de <b>PageElement</b>.
          <br/><br/>
          Ainsi, les traductions de <b>text1</b> et <b>text2</b> sont disponibles dans <b>MonAutrePage1</b>.<br/>
          <br/>
          La récupération des traductions se fait via la méthode <b>this._tl('clé')</b> qui renvoie la traduction de la clé en fonction de la langue courante.
          <br/><br/>
          <u>Hiérarchie des composants :</u>
          <sl-tree>
            <sl-tree-item expanded>LitElement
              <sl-tree-item expanded>BaseElement: implémentation i18next
                <sl-tree-item expanded>FullPageElement: page de base, sans marge
                  <sl-tree-item expanded>PageElement: page de base, avec marge
                    <sl-tree-item expanded>MaPage: page spécifique, déclaration clé "text1"
                      <sl-tree-item expanded>MonAutrePage1: sous page, déclaration clé "text2"
                    </sl-tree-item>
                  </sl-tree-item>
                </sl-tree-item>
              </sl-tree-item>
            </sl-tree-item>
          </sl-tree>
        </sl-tab-panel>

        <sl-tab-panel name="test">
          <ul>
            <li>Clé "add", provenance classe parent: &laquo;&nbsp;&nbsp;<strong tid="text1">${this._tl('text1')}</strong>&nbsp;&nbsp;&raquo;</li><br/>
            <li>Clé "text", provenance classe actuelle: &laquo;&nbsp;&nbsp;<strong tid="text2">${this._tl('text2')}</strong>&nbsp;&nbsp;&raquo;</li>
          </ul>
          ${this.testPassed === 2 
            ? html`<p style="color:green">✅ Test passed</p>`
            : html`<p style="color:red"> ❌ Test failed</p>`
          }
        </sl-tab-panel>

      </sl-tab-group>

      <br/><br/>
    `;
  }

}

customElements.define('page-tests-i18n', PageI18nMain);
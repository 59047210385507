import { PageElement, html, css, highlight } from 'Elements';
import styles from './styles.css.js';
import { Lang } from 'Utils';

class SelectCustomerPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        .flex {
          display:flex;
          align-items:center;
          gap:20px;
        }

        .flex > * {
          width:100%;
        }

        b {
          font-size:2em;
          height:40px;
        }
      `
    ]
  }

  static get properties() {
    return {
      value1: { type: String },
      value2: { type: String },
      value3: { type: String },
      value4: { type: String },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.value1 = '';
  }

  onChange1(ev) {
    console.log(ev.detail.value);
    this.value1 = ev.detail.value;
  }

  renderTreeItems(items) {
    const iconCss = 'float:left;font-size:1em;margin-top:2px;margin-right:3px;';
    return items
      .map(item => {
        
        const icon = item.parentId 
          ? html`<m-icon name="sell" style="${iconCss};color:${item.color}"></m-icon>` 
          : html`<m-icon name="label"  style="${iconCss};color:${item.color}"></m-icon>`;

        return html`
          <sl-tree-item expanded class="tree-item ${item.hidden ? 'hidden' : ''}" ?selected=${this.isSelected(item)} value="${item[this.primaryKey]}" .item=${item}>
            <span class="title">
              ${icon}
              ${highlight(Lang.lookup(item, this.displayKey), this.q)}
            </span>
            ${item.children?.length ? this.renderTreeItems(item.children) : ''}
          </sl-tree-item>
        `
      });
  }

  render() {
    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;select-search-tree&gt;</h3>
        <div>Selecteur arborescence avec recherche</div>
      </div>

      <sl-tab-group>  
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">
          <div>
            
            Multiple valeurs, virtualisé, dynamique (API), loader, multilingue, template fonction, separateur ","<br/>
            <div class="flex">
              <select-search-tree
                name="select1" 
                selection="leaf"
                max-options-visible="2" 
                value="${this.value1}" 
                parent-show
                parent-separator=": "
                parent-include
                clearable
                api="private/soc/settings/classifications"
                primary-key="_id"
                display-key="title"
                .renderTreeItems=${this.renderTreeItems}
                @change=${this.onChange1}
              ></select-search-tree>
              <div>Valeur: ${this.value1}</div>
            </div>

          </div>
        </sl-tab-panel>
      </sl-tab-group>
      
    `;
  }
}

customElements.define('page-tests-select-search-tree', SelectCustomerPage);
import { PageElement, html } from 'Elements';
import styles from './styles.css.js';

class SLTabGroupVertPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles
    ];
  }
  
  render() {

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;sl-tab-group&gt;</h3>
        <div>Boîte à onglets, composant natif shoelace</div>
      </div>
      <sl-tab-group>
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">

          <sl-tab-group>
            <sl-tab slot="nav" panel="general">General</sl-tab>
            <sl-tab slot="nav" panel="custom">Custom</sl-tab>
            <sl-tab slot="nav" panel="advanced">Advanced</sl-tab>
            <sl-tab slot="nav" panel="disabled" disabled>Disabled</sl-tab>

            <sl-tab-panel name="general">
              <sl-tab-group placement="start">
                <sl-tab slot="nav" panel="general">General</sl-tab>
                <sl-tab slot="nav" panel="custom">Custom</sl-tab>
                <sl-tab slot="nav" panel="advanced">Advanced</sl-tab>
                <sl-tab slot="nav" panel="disabled" disabled>Disabled</sl-tab>

                <sl-tab-panel name="general">This is the general tab panel.</sl-tab-panel>
                <sl-tab-panel name="custom">This is the custom tab panel.</sl-tab-panel>
                <sl-tab-panel name="advanced">This is the advanced tab panel.</sl-tab-panel>
                <sl-tab-panel name="disabled">This is a disabled tab panel.</sl-tab-panel>
              </sl-tab-group>
            </sl-tab-panel>

            <sl-tab-panel name="custom">

              <sl-tab-group placement="end">
                <sl-tab slot="nav" panel="general">General</sl-tab>
                <sl-tab slot="nav" panel="custom">Custom</sl-tab>
                <sl-tab slot="nav" panel="advanced">Advanced</sl-tab>
                <sl-tab slot="nav" panel="disabled" disabled>Disabled</sl-tab>

                <sl-tab-panel name="general">This is the general tab panel.</sl-tab-panel>
                <sl-tab-panel name="custom">This is the custom tab panel.</sl-tab-panel>
                <sl-tab-panel name="advanced">This is the advanced tab panel.</sl-tab-panel>
                <sl-tab-panel name="disabled">This is a disabled tab panel.</sl-tab-panel>
              </sl-tab-group>

            </sl-tab-panel>
            <sl-tab-panel name="advanced">This is the advanced tab panel.</sl-tab-panel>
            <sl-tab-panel name="disabled">This is a disabled tab panel.</sl-tab-panel>
          </sl-tab-group>
        </sl-tab-panel>
      </sl-tab-group>
    `;
  }
}

customElements.define('page-tests-sl-tab-group', SLTabGroupVertPage);
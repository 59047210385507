import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';

class SelectCustomerPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        .flex {
          display:flex;
          align-items:center;
          gap:20px;
        }

        .flex > * {
          width:100%;
        }

        b {
          font-size:2em;
          height:40px;
        }
      `
    ]
  }

  static get properties() {
    return {
      value1: { type: String },
      value2: { type: String },
      value3: { type: String },
      value4: { type: String },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.value1 = '1';
    this.value2 = '2 3 4';
    this.value3 = '1,5,1000';
    this.value4 = '671de1142d2aeb8e0a10eb15,671de1142d2aeb8e0a10eb14';
  }

  onChange1(ev) {
    this.value1 = ev.detail.value;
  }

  onChange2(ev) {
    this.value2 = ev.detail.value;
  }

  onChange3(ev) {
    this.value3 = ev.detail.value;
  }

  onChange4(ev) {
    this.value4 = ev.detail.value;
  }

  renderItems(item) {
    // this function is binded to select-search component
    return html`<sl-menu-item type="checkbox" ?checked=${this.isSelected(item)} value="${this.getKey(item)}">${this.getLabel(item)}</sl-menu-item>`;
  }

  render() {

    const max = 100000;
    const items = Array.from({ length: max }, (_, i) => {
      i+=1;
      return { id: `${i}`, name: 'Client '+i };
    });

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;select-search&gt;</h3>
        <div>Selecteur avec recherche</div>
      </div>

      <sl-tab-group>  
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">
          <div>

            Mono valeur<br/>
            <div class="flex">
              <select-search name="select1" clearable max-options-visible="2" value="${this.value1}" separator="," @sl-change=${this.onChange1}>
                <sl-option value="1">Client 1</sl-option>
                <sl-option value="2">Client 2</sl-option>
                <sl-option value="3">Client 3</sl-option>
                <sl-option value="4">Client 4</sl-option>
                <sl-option value="5">Client 5</sl-option>
                <sl-option value="6">Client 6</sl-option>
                <sl-option value="7">Client 7</sl-option>
                <sl-option value="8">Client 8</sl-option>
                <sl-option value="9">Client 9</sl-option>
                <sl-option value="10">Client 10</sl-option>
                <sl-option value="11">Client 11</sl-option>
                <sl-option value="12">Client 12</sl-option>
                <sl-option value="13">Client 13</sl-option>
                <sl-option value="14">Client 14</sl-option>
                <sl-option value="15">Client 15</sl-option>
                <sl-option value="16">Client 16</sl-option>
                <sl-option value="17">Client 17</sl-option>
                <sl-option value="18">Client 18</sl-option>
                <sl-option value="19">Client 19</sl-option>
                <sl-option value="20">Client 20</sl-option>
              </select-search>
              <div>Valeur: ${this.value1}</div>
            </div>
            <br/>


            Multiple valeurs, separateur " "<br/>
            <div class="flex">
              <select-search name="select2" clearable multiple max-options-visible="2" value="${this.value2}"  separator=" "  @sl-change=${this.onChange2}>
                <sl-option value="1">Client 1</sl-option>
                <sl-option value="2">Client 2</sl-option>
                <sl-option value="3">Client 3</sl-option>
                <sl-option value="4">Client 4</sl-option>
                <sl-option value="5">Client 5</sl-option>
                <sl-option value="6">Client 6</sl-option>
                <sl-option value="7">Client 7</sl-option>
                <sl-option value="8">Client 8</sl-option>
                <sl-option value="9">Client 9</sl-option>
                <sl-option value="10">Client 10</sl-option>
                <sl-option value="11">Client 11</sl-option>
                <sl-option value="12">Client 12</sl-option>
                <sl-option value="13">Client 13</sl-option>
                <sl-option value="14">Client 14</sl-option>
                <sl-option value="15">Client 15</sl-option>
                <sl-option value="16">Client 16</sl-option>
                <sl-option value="17">Client 17</sl-option>
                <sl-option value="18">Client 18</sl-option>
                <sl-option value="19">Client 19</sl-option>
                <sl-option value="20">Client 20</sl-option>
              </select-search>
              <div>Valeur: ${this.value2}</div>
            </div>
            <br/>

            Multiple valeurs, virtualisé, ${items.length} éléments, separateur ","<br/>
            <div class="flex">
              <select-search
                name="select3" 
                multiple 
                virtualize
                max-options-visible="2" 
                value="${this.value3}" 
                separator=","
                @sl-change=${this.onChange3}
                .items=${items}
              ></select-search>
              <div>Valeur: ${this.value3}</div>
            </div>
            <br/>
            
            Multiple valeurs, virtualisé, dynamique (API), loader, multilingue, template fonction, separateur ","<br/>
            <div class="flex">
              <select-search
                name="select3" 
                multiple 
                virtualize
                max-options-visible="2" 
                value="${this.value4}" 
                separator=","
                api="private/admin/system/holidays"
                primary-key="_id"
                display-key="name"
                .renderItems=${this.renderItems}
                @sl-change=${this.onChange4}
              ></select-search>
              <div>Valeur: ${JSON.stringify(this.value4.split(','), null, 4)}</div>
            </div>

          </div>
        </sl-tab-panel>
      </sl-tab-group>
      
    `;
  }
}

customElements.define('page-tests-select-search', SelectCustomerPage);
import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';

class ImageBankPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        pre {
          padding:20px;
          margin:10px;
          font-size:0.9em;
          border:1px solid var(--sl-color-neutral-300)
        }

        p {
          font-size:1.2em;
          margin-left:40px;
        }

        .flex {
          display:flex;
          align-items:center
        }
      `
    ]
  }

  static get properties() {
    return {
      value: { type: String }
    };
  }

  constructor() {
    super();
    //this.value = '**test**';
    this.value = 'assets/img/visuals/ddos.webp';
  }

  onChange(e) {
    this.value = e.target.value;
    console.log('new value', this.value);
  }

  render() {
    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>

      <div class="title">
        <h3>&lt;image-bank&gt;</h3>
        <div>Un gestionnaire d'images avec un wrapper on top of <a href="https://fengyuanchen.github.io/cropperjs/" target="_blank">Cropper.js</a>.</div>
      </div>
   
      <sl-tab-group>
        
        <sl-tab slot="nav" panel="tab1">Composant</sl-tab>
        <sl-tab slot="nav" panel="tab2">Status</sl-tab>
        <sl-tab-panel name="tab1">
          <image-bank height="50vh" src=${this.value} @input=${this.onChange}></image-bank>
        </sl-tab-panel>

        <sl-tab-panel name="tab2">
          <br/><br/>
        </sl-tab-panel>

      </sl-tab-group>

      <br/><br/>
    `;
  }

}

customElements.define('page-tests-image-bank', ImageBankPage);
import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';

class SelectEnableRecursivePage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        .flex {
          display:flex;
          align-items:center;
          gap:20px;
          margin-bottom:20px;
        }

        b {
          font-size:2em;
          height:40px;
        }

        select-enabler {
          width:200px;
        }

        .flex {
          display:flex;
          gap:20px;
          align-items:baseline;
        }
      `
    ]
  }

  static get properties() {
    return {
      value: { type: String },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.value = '';
  }

  async updated(changedProperties) {
    if (changedProperties.has('value')) {
      //this._log.debug('updated: value:', this.value);
    }
  }

  onChange(ev) {
    this.qs(`.${ev.target.name}`).innerHTML = ev.target.value+' '+typeof ev.target.value;
  }

  render() {

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;select-enabler&gt;</h3>
        <div>Un selecteur de permission</div>
      </div>
      <sl-tab-group>  
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">
          <div class="flex">
            <select-enabler name="s1" merged="true" value="" @sl-change=${this.onChange}></select-enabler>
            <div class="s1">initial value = empty, merged="true"</div>
          </div>
          <div class="flex">
            <select-enabler name="s1b" merged=true  @sl-change=${this.onChange}></select-enabler>
            <div class="s1b">initial value = empty, merged=true</div>
          </div>
          <div class="flex">
            <select-enabler name="s2" @sl-change=${this.onChange}></select-enabler>
            <div class="s2">initial value = no value</div>
          </div>
          <div class="flex">
            <select-enabler name="s3" value=true @sl-change=${this.onChange}></select-enabler>
            <div class="s3">initial value = true</div>
          </div>
          <div class="flex">
            <select-enabler name="s4" value=false @sl-change=${this.onChange}></select-enabler>
            <div class="s4">initial value = false</div>
          </div>
          <div class="flex">
            <select-enabler name="s5" value="true" @sl-change=${this.onChange}></select-enabler>
            <div class="s5">initial value = "true"</div>
          </div>
          <div class="flex">
            <select-enabler name="s6" value="false" @sl-change=${this.onChange}></select-enabler>
            <div class="s6">initial value = "false"</div>
          </div>
        </sl-tab-panel>
      </sl-tab-group>
      <br/><br/><br/><br/>
    `;
  }
}

customElements.define('page-tests-select-enabler', SelectEnableRecursivePage);
import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';
import 'Components/business/select-severity.js';

class SelectSeverityPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        .flex {
          display:flex;
          align-items:center;
          gap:20px;
        }

        .flex > * {
          width:100%;
        }

        b {
          font-size:2em;
          height:40px;
        }
      `
    ]
  }

  static get properties() {
    return {
      value: { type: String },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.value = 'P1';
  }

  onChange(ev) {
    this.value = ev.detail;
    console.log(this.value);
  }


  render() {

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;select-severity&gt;</h3>
        <div>Selecteur de sévérité</div>
      </div>

      <sl-tab-group>  
        <sl-tab slot="nav" panel="component">Composant</sl-tab>

        <sl-tab-panel name="component">
          <div class="flex">
            <select-severity name="select1" size="small" clearable hoist value=${this.value} @change=${this.onChange}></select-severity>
            <div style="flex:0.5">Valeur: ${this.value}</div>
          </div>
        </sl-tab-panel>

      </sl-tab-group>
      
    `;
  }
}

customElements.define('page-tests-select-severity', SelectSeverityPage);
import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';

class InputBusinessHoursPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        p {
          font-size:1.2em;
          margin-left:40px;
        }

        .flex {
          display:flex;
          align-items:center;
          gap:10px;
          align-items:flex-start;
        }

        .flex > * {
          flex-grow:1;
        }
      `
    ]
  }

  static get properties() {
    return {
      value: { type: Object },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.value = null;
  }

  onPeriodChange(ev) {
    this.value = ev.detail;
    console.log(this.value);
  }

  render() {

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;input-business-hours&gt;</h3>
        <div>Un composant pour sélectionner les heures ouvrées.</div>
      </div>

      <sl-tab-group>
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">
          <div class="flex">
            <input-business-hours label="Heures ouvrées" value="" @change=${this.onChange}></input-business-hours>
            <box-json .value=${this.value}></b>
          </div>
        </sl-tab-panel>

      </sl-tab-group>

      <br/><br/><br/>
    `;
  }
}

customElements.define('page-tests-input-business-hours', InputBusinessHoursPage);
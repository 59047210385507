import { PageElement, html } from 'Elements';
import styles from './styles.css.js';

class SectionHeaderTestPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles
    ];
  }
  
  render() {
    const text1 = 'left icon';
    const text2 = 'without icon';
    const text3 = 'both LR icon';
    const text4 = 'left icon right button';
    const button = html`<sl-button slot="right" size="small">Button</sl-button>`;
    const ricon = html`<m-icon slot="right" name="edit"></m-icon>`;

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;section-header&gt;</h3>
        <div>Section de pages</div>
      </div>
      <sl-tab-group>
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">
          <section-header micon="error">big: ${text1}</section-header><br/>
          <section-header micon="error" size="medium">medium: ${text1}</section-header><br/>
          <section-header micon="error" size="normal">normal: ${text1}</section-header><br/>
          <section-header micon="error" size="small">small: ${text1}</section-header><br/>
          <section-header micon="error" size="vsmall">vsmall: ${text1}</section-header><br/>
          <br/>
          <section-header>big: ${text2}</section-header><br/>
          <section-header size="medium">medium: ${text2}</section-header><br/>
          <section-header size="normal">normal: ${text2}</section-header><br/>
          <section-header size="small">small: ${text2}</section-header><br/>
          <section-header size="vsmall">vsmall: ${text2}</section-header><br/>
          <br/>
          <section-header micon="error">big: ${text3}${ricon}</section-header><br/>
          <section-header micon="error" size="medium">medium: ${text3}${ricon}</section-header><br/>
          <section-header micon="error" size="normal">medium: ${text3}${ricon}</section-header><br/>
          <section-header micon="error" size="small">small: ${text3}${ricon}</section-header><br/>
          <section-header micon="error" size="vsmall">vsmall: ${text3}${ricon}</section-header><br/>
          <br/>
          <section-header micon="error">big: ${text4}${button}</section-header><br/>
          <section-header micon="error" size="medium">medium: ${text4}${button}</section-header><br/>
          <section-header micon="error" size="normal">normal: ${text4}${button}</section-header><br/>
          <section-header micon="error" size="small">small: ${text4}${button}</section-header><br/>
          <section-header micon="error" size="vsmall">vsmall: ${text4}${button}</section-header><br/>
          <br/>
        </sl-tab-panel>
      </sl-tab-group>

    `;
  }
}

customElements.define('page-tests-section-header', SectionHeaderTestPage);
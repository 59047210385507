import { PageElement, html, css } from 'Elements';
import Logo from '../../../../Logo.svg.js';
import 'Components/public/RevealPrez.js';
import 'Components/authenticated/ApexChart.js';
import './apex-chart/tab_realtime.js';
import styles from './styles.css.js';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      styles,
      css`
        :host {
          height:100vh;
        }

        .fullscreen {
          height:100vh;
        }

        .fullscreen .title {
          color: white;
          margin-left: 50px;
          padding-left: 10px;
          margin-top: 3px;
          padding-top: 2px;
          font-size: 1.3em;
          height: 44px;
        }

        .logo {
          display:none;
          overflow:hidden;
          max-width:270px;
        }

        .logo svg {
          height: 50px;
          margin-top:4px;
        }

        .logo svg .st0 {
          fill:white;
        }

        .logo svg .st1 {
          fill:#1B7DC2;
        }

        .fullscreen {
          background-color: var(--nsys-blue5);
        }

        .fullscreen .logo {
          display:flex;
        }

        .fullscreen h3 {
          color:white;
          font-size:2em;
          display: flex;
          gap: 50px;
          justify-content: start;
          align-items: center;
        }

        .fullscreen m-icon {
          display:none;
        }

        m-icon {
          cursor:pointer;
        }
      `
    ];
  }

  /*

  toggleFullscreen() {
    const revealElement = this.shadowRoot.querySelector('reveal-prez');
    revealElement.toggleFullscreen();
  }
  */

  constructor() {
    super();
    this.debug = false;
    this.handleFullscreenChange = this.handleFullscreenChange.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('fullscreenchange', this.handleFullscreenChange);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
    if (this.realtimeChart) this.realtimeChart.hide();
  }

  firstUpdated() {
    super.firstUpdated();
    this.modalHelp = this.shadowRoot.querySelector('#modal-reveal-help');
    this.revealElement = this.shadowRoot.querySelector('reveal-prez');
    setTimeout(() => {
      this.replacePrez();
    }, 5000);
  }

  replacePrez() {
    this._log.debug('replacePrez');
    this.revealElement.setSlides(`
      <section>
        <h2>Présentation dynamique</h2>
        <br/>
        La présentation a été entièrement remplacée par une autre.
      </section>
    `);

    setTimeout(() => {
      this.renderSlides();
    }, 5000);
  }

  renderSlides() {

    const randomArray = Array(12).fill(0);
    this.chartLabels = [ 'Jan', 'Fév', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc' ];
    this.chartLabelsInt = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];
    this.chartColors = [ 'hsl(0, 84.2%, 42%)', 'hsl(24.6, 95%, 42%)', 'hsl(120, 61%, 42%)' ];
    this.chartData = [
      { name: 'Série 1', data: [ ...randomArray ] },
      { name: 'Série 2', data: [ ...randomArray ] },
      { name: 'Série 3', data: [ ...randomArray ] }
    ]
    this.randomizeGraphData();

    const renderSlides = [
      { slide: this.renderSlideSimple, duration: 4000 },
      { slide: this.renderSlideGraphStatic, duration: 4000 },
      { slide: this.renderSlideGraphUpdate, duration: 8000 },
      { slide: this.renderSlideGraphRealtime, duration: 8000 },
      { slide: this.renderSlideThanks, duration: 10000 }
    ];

    let totalDuration = 0;

    for (const rslide of renderSlides) {
      totalDuration += rslide.duration;
      setTimeout(() => { rslide.slide.call(this); }, totalDuration);
    }
  }

  randomBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  renderSlideSimple() {
    this.revealElement.pushSlide(`
      <section>
        <h2>Nouveau slide !</h2>
        <br/>
        Cette slide a été ajouté dynamiquement dans la même présentation.
      </section>
    `);
  }

  renderSlideThanks() {
    this.revealElement.pushSlide(`
      <section>
        <h2>Merci.</h2>
        <img src="/assets/img/cert.png"/>
        <br/>
      </section>
    `);
  }

  async renderSlideGraphStatic() {

      // We can not pass component parameters here for now
      // @TODO: learn "ref" lit-element stuff ..
      const prezEl = await this.revealElement.pushSlide(`
        <section>
          <h4>Graphique statique</h4>
          <apex-chart style="height:80%;" id="cbar" type="bar" labelFontSize="20px" strokeWidth=1 legendFontSize="20px" labelHide></apex-bar>
        </section>
      `);

      // so we have to query the element and set the properties after the slide has been rendered
      const chart = prezEl.querySelector('#cbar');
      if (chart) {
        chart.series = this.chartData;
        chart.labels = this.chartLabels;
        chart.colors = this.chartColors;
      }
  }

  randomizeGraphData() {
    for (let i = 0; i < this.chartData.length; i++) {
      this.chartData[i].data = this.chartData[i].data.map((i, v) => {
        if (v<3) return 0;
        return this.randomBetween(0, 100);
      });
    }
  }

  async renderSlideGraphUpdate() {

      // We can not pass component parameters here for now
      // @TODO: learn "ref" lit-element stuff ..
      const prezEl = await this.revealElement.pushSlide(`
        <section>
          <h4>Graphique dynamique</h4>
          <apex-chart style="height:80%;" id="carea1" type="area" labelFontSize="18px" legendFontSize="20px" labelHide animationsEnabled></apex-splinearea>
        </section>
      `);

      // so we have to query the element and set the properties after the slide has been rendered
      const chart = prezEl.querySelector('#carea1');
      chart.series = this.chartData;
      chart.labels = this.chartLabels;
      chart.colors = this.chartColors;
      
      let j = 0;
      this.timerUpdateGraph = setInterval(() => {
        this.randomizeGraphData();
        chart.updateSeries(this.chartData);
        if (j++>5) clearInterval(this.timerUpdateGraph);
      }, 3000);
  }

  async renderSlideGraphRealtime2() {

      // We can not pass component parameters here for now
      // @TODO: learn "ref" lit-element stuff ..
      const prezEl = await this.revealElement.pushSlide(`
        <section>
          <h4>Graphique temps réel</h4>
          <apex-chart style="height:80%;" id="carea2" type="line" labelFontSize="18px" legendFontSize="20px" labelHide legendHide animationsEnabled></apex-chart>
        </section>
      `);

      // so we have to query the element and set the properties after the slide has been rendered
      const chart = prezEl.querySelector('#carea2');

      let lastDate = 0;
      const data = [];
      const TICKINTERVAL = 86400000
      const XAXISRANGE = 777600000
      
      function getDayWiseTimeSeries(baseval, count, yrange) {
        let i = 0;
        while (i < count) {
          const x = baseval;
          const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
          data.push({ x, y });
          lastDate = baseval
          baseval += TICKINTERVAL;
          i++;
        }
      }
  
      getDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 10, { min: 10, max: 90 })
  
      function getNewSeries(baseval, yrange) {
        const newDate = baseval + TICKINTERVAL;
        lastDate = newDate;
  
        for(let i = 0; i< data.length - 10; i++) {
          // IMPORTANT
          // we reset the x and y of the data which is out of drawing area
          // to prevent memory leaks
          data[i].x = newDate - XAXISRANGE - TICKINTERVAL
          data[i].y = 0
        }
  
        data.push({
          x: newDate,
          y: Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
        })
      }

      chart.xAxisType = 'datetime';
      chart.xAxisRange = XAXISRANGE;
      chart.yAxisMax = 100;

      //chart.setSeries([{ data: data.slice() }]);

      const options = {
        series: [{ data: data.slice() }],
        chart: {
          height: 350,
          type: 'line',
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: { speed: 1000 }
          },
          toolbar: { show: false },
          zoom: { enabled: false }
        },
        dataLabels: { enabled: false },
        stroke: { curve: 'smooth' },
        title: { text: 'Dynamic Updating Chart', align: 'left' },
        markers: { size: 0 },
        xaxis: { type: 'datetime', range: XAXISRANGE },
        yaxis: { max: 100 },
        legend: { show: false },
      };

      chart.updateOptions(options);
      
      let intervalRuns = 0;
      const interval = setInterval(() => {
        intervalRuns++
        getNewSeries(lastDate, { min: 10, max: 90 })      
        chart.series = ([{ data }]);
        if (intervalRuns === 30) {
          clearInterval(interval)
        }
      }, 1000)
  }

  async renderSlideGraphRealtime() {
    const prezEl = await this.revealElement.pushSlide(`
      <section>
        <h4>Graphique temps réel</h4>
        <tab-apex-realtime></tab-apex-realtime>
      </section>
    `);

    this.realtimeChart = prezEl.querySelector('tab-apex-realtime');
    this.realtimeChart.show();
  }

  handleFullscreenChange() {
   const el = this.shadowRoot.querySelector('.container');
    if (!document.fullscreenElement) {
      el.classList.remove('fullscreen');
      this.shadowRoot.querySelector('reveal-prez').resize(false);
    }
  }

  async toggleFullscreen() {
    const el = this.shadowRoot.querySelector('.container');
    if (!document.fullscreenElement) {
      await el.requestFullscreen();
      el.classList.add('fullscreen');
      this.shadowRoot.querySelector('reveal-prez').resize(true);
    } else {
      document.exitFullscreen();
      el.classList.remove('fullscreen');
      this.shadowRoot.querySelector('reveal-prez').resize(false);
    }
  }

  showHelp() {
    this.modalHelp.show();
  }

  render() {
    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;reveal-prez&gt;</h3>
        <div>Un wrapper on top of <a href="https://revealjs.com/" target="reveal">reveal.js</a>.</div>
      </div>

      <sl-tab-group>
        <sl-tab slot="nav" panel="component">Composant</sl-tab>

        <sl-tab-panel name="component">

        <div class="container">

          <section-header>
            <span class="logo">${Logo}</span>
            <span class="title">Titre présentation</span>
            <span slot="right">
              <m-icon name="help" @click="${this.showHelp}"></m-icon>
              <m-icon name="fullscreen" @click="${this.toggleFullscreen}"></m-icon>
            </span>
          </section-header>
          
          <reveal-prez>
            
            <section>
              <h2>Exemple de présentation</h2>
              <br/>
              Bienvenue !
            </section>

            <section>
              <h3>Provenance des incidents</h3>
              <aside class="notes">Shhh, these are your private notes 📝</aside>
              <p class="fragment">Boîte mail csirt@sysdream.io</p>
              <p class="fragment">Alerte HarfangLab</p>
              <p class="fragment">Déclaration d'incident en ligne</p>
            </section>

            <section>Quelques tests revealjs</section>

            <section>
              <h3>Code</h3>
              <pre class="code-wrapper">
                <code data-line-numbers="3|8-10|13-14">
rule Attachments
  strings:
    $doc = ".doc"
    $htm = ".htm"
    $exe = ".exe"
    $pdf = ".pdf"
    $excel = ".xlsx"
    $ics = ".ics"
    $png = ".png"
    $jpg = ".jpg"
    $gif = ".gif"
  condition:
    any of them

                </code>
              </pre>
            </section>

          </reveal-prez>
        </div>
      </sl-tab-panel>
    </sl-tab-group>
      
    <modal-dialog id="modal-reveal-help" label="Aide">
      <p>
        <b>Touches spéciales</b>
        <ul style="margin-left:20px;">
          <li>O pour Overview</li>
          <li>S pour Speaker</li>
        </ul>
      </p>
    </modal-dialog>
    `;
  }
}

customElements.define('page-tests-reveal', Page);

import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      styles,
      css`
      `
    ];
  }

  render() {
    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3><b>&lt;modal-drawer&gt;</b></h3>
        <div>Refactor du composant shoelace <a href="https://shoelace.style/components/drawer" target="shoelace">sl-drawer</a>.</div>
      </div>

      <sl-tab-group>
        <sl-tab slot="nav" panel="component">Composant</sl-tab>

        <sl-tab-panel name="component">
          <ul>
            <li>modal ou non modal : gestion mousewheel différente</li>
            <li>meilleur maîtrise de l'overlay (fondu progressif vers la suppression des couleurs via <i>backdrop-filter</i>)</li>
            <li>slots pour les boutons</li>
          </ul>
          <br/>

          <sl-button @click=${() => this.open('modal')}>Ouvrir le drawer (modal)</sl-button>
          <sl-button @click=${() => this.open('nomodal')}>Ouvrir le drawer (non modal)</sl-button>
        </sl-tab-panel>
      </sl-tab-group>
      
      <modal-drawer id="modal" label="drawer title" modal>
        Hello world !
        <sl-button slot="bt1" close="true" variant="primary">slot bt1 - OK</sl-button>
        <sl-button slot="bt2" close="true" variant="text">slot bt2 - Cancel</sl-button>
      </modal-drawer>

      <modal-drawer id="nomodal" label="drawer title">
        Hello world !
        <sl-button slot="bt1" close="true" variant="primary">slot bt1 - OK</sl-button>
        <sl-button slot="bt2" close="true" variant="text">slot bt2 - Cancel</sl-button>
      </modal-drawer>
    `;
  }

  open(id) {
    this.shadowRoot.querySelector(`#${id}`).show();
  }

}

customElements.define('page-tests-modal-drawer', Page);
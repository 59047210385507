import { css } from 'lit-element';

export default css`
  .title {
    display:flex;
    align-items:baseline;
    gap:20px;
  }

  .title h3 {
    font-weight:bold;
  }
`;


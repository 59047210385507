import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';

class RangeHoursPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        p {
          font-size:1.2em;
          margin-left:40px;
        }

        .flex {
          display:flex;
          align-items:center;
          gap:5px;
          margin-bottom:5px;
        }

        .item {
          height:40px;          
          display:flex;
          align-items:center;
          padding:5px;
          border:1px solid var(--sl-color-neutral-300);
        }

        .label {
          width:220px;
        }

        range-numbers {
          width:400px;
        }

        .result {
          width:200px;
        }
      `
    ]
  }

  static get properties() {
    return {
      value1: { type: Object },
      value2: { type: Object },
      value3: { type: Object },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.value1 = '3_9';
    this.value2 = '100_200';
    this.value3 = '480_1080';
  }

  onChange1(ev) {
    this.value1 = ev.detail;
  }

  onChange2(ev) {
    this.value2 = ev.detail;
  }

  onChange3(ev) {
    this.value3 = ev.detail;
  }

  testIncrement() {
    this.testInterval = setInterval(() => {
      // increment the last part of value3 taking his value, add 60*step
      const r = this.qs('range-numbers[name=range3]');
      if (!r) {
        clearInterval(this.testInterval);
        return;
      }
      let v = r.value.split('_');
      const lower = parseFloat(v[0]);
      const upper = parseFloat(v[1]);
      const step = 0.5;
      const newUpper = upper + 60*step;
      if (newUpper > 1440) {
        clearInterval(this.testInterval);
        return;
      }
      v = `${lower}_${newUpper}`;
      //r.value = v;
      this.value3 = v;
    }, 1000);
  }

  firstUpdated() {
    this.testIncrement();
  }

  render() {

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;range-numbers&gt;</h3>
        <div>Un composant pour sélectionner des ranges de nombre ou horaires.</div>
      </div>

      <sl-tab-group>
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">

          <div class="flex">
            <div class="item label">Nombres entiers - step 1</div>
            <div class="item"><range-numbers value="${this.value1}" @change=${this.onChange1}></range-numbers></div>
            <div class="item result">${JSON.stringify(this.value1)}</div>
          </div>

          <div class="flex">
            <div class="item label">Heures et minutes - step 1h</div>
            <div class="item"><range-numbers value="${this.value2}" units="hourminute" @change=${this.onChange2}></range-numbers></div>
            <div class="item result">${JSON.stringify(this.value2)}</div>
          </div>

          <div class="flex">
            <div class="item label">Heures et minutes - step 30mn</div>
            <div class="item">
              <table>
                <tbody>
                <tr>
                  <td>
                    <range-numbers step="0.5" value="${this.value3}" units="hourminute" @change=${this.onChange3} name="range3"></range-numbers>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="item result">${JSON.stringify(this.value3)}</div>
          </div>

        </sl-tab-panel>

      </sl-tab-group>

      <br/><br/><br/>
    `;
  }
}

customElements.define('page-tests-range-numbers', RangeHoursPage);